import { Routes } from '@angular/router';
//Layouts
import { 
  //CondensedComponent,
  BlankComponent,
  //CorporateLayout,
  SimplyWhiteLayout,
  //ExecutiveLayout,
  //CasualLayout ,
  //BlankCasualComponent,
  //BlankCorporateComponent,
  BlankSimplywhiteComponent
} from './@pages/layouts';
import { AuthGaurd } from './shared/guards/auth-guard.service';
import { RoleGuard } from './shared/guards/role-guard';


export const AppRoutes: Routes = [

  {
    path: '',
    canActivate: [AuthGaurd,RoleGuard],
    data: {
      permission:''
    },
    component: SimplyWhiteLayout,
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'user',
    canActivate: [AuthGaurd,RoleGuard],
    data: {
      permission:'view_admin'
    },
    component: SimplyWhiteLayout,
    loadChildren: './view/user/user.module#UserModule',
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'seller_user',
    canActivate: [AuthGaurd,RoleGuard],
    data: {
      permission:'view_admin'
    },
    component: SimplyWhiteLayout,
    loadChildren: './view/seller_user/user.module#SellerUserModule',
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'venue',
    canActivate: [AuthGaurd,RoleGuard],
    data: {
      permission:'view_venue'
    },
    component: SimplyWhiteLayout,
    loadChildren: './view/venue/venue.module#VenueModule',
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'offer',
    canActivate: [AuthGaurd,RoleGuard],
    data: {
      permission:'view_offer'
    },
    component: SimplyWhiteLayout,
    loadChildren: './view/offer/offer.module#OfferModule',
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'product',
    canActivate: [AuthGaurd,RoleGuard],
    data: {
      permission:'view_product'
    },
    component: SimplyWhiteLayout,
    loadChildren: './view/product/product.module#ProductModule',
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'device',
    canActivate: [AuthGaurd,RoleGuard],
    data: {
      permission:'view_device'
    },
    component: SimplyWhiteLayout,
    loadChildren: './view/device/device.module#DeviceModule',
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'forum',
    canActivate: [AuthGaurd,RoleGuard],
    data: {
      permission:'view_forum'
    },
    component: SimplyWhiteLayout,
    loadChildren: './view/forum/forum.module#ForumModule',
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'sales_report',
    canActivate: [AuthGaurd,RoleGuard],
    data: {
      permission:'view_reports'
    },
    component: SimplyWhiteLayout,
    loadChildren: './view/sales_report/sales_report.module#SalesReportModule',
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'activation_report',
    canActivate: [AuthGaurd,RoleGuard],
    data: {
      permission:'view_reports'
    },
    component: SimplyWhiteLayout,
    loadChildren: './view/activation_report/activation_report.module#ActivationReportModule',
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'order',
    canActivate: [AuthGaurd,RoleGuard],
    data: {
      permission:'view_order'
    },
    component: SimplyWhiteLayout,
    loadChildren: './view/order/order.module#OrderModule',
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'activation',
    canActivate: [AuthGaurd,RoleGuard],
    data: {
      permission:'view_activation'
    },
    component: SimplyWhiteLayout,
    loadChildren: './view/activation/activation.module#ActivationModule',
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'customer',
    canActivate: [AuthGaurd,RoleGuard],
    data: {
      permission:''
    },
    component: SimplyWhiteLayout,
    loadChildren: './view/customer/customer.module#CustomerModule',
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'profile',
    canActivate: [AuthGaurd,RoleGuard],
    data: {
      permission:''
    },
    component: SimplyWhiteLayout,
    loadChildren: './view/profile/profile.module#ProfileModule',
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'dashboard',
    canActivate: [AuthGaurd,RoleGuard],
    data: {
      permission:''
    },
    component: SimplyWhiteLayout,
    loadChildren: './view/dashboard/dashboard.module#DashboardModule',
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'session',
    data: {
      permission:'view_dashboard'
    },
    component: BlankSimplywhiteComponent,
    loadChildren: "./view/session/session.module#SessionModule"
  },
];
