import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    private http : HttpClient
  ) { }

    getAll(searchParams){
      return this.http.get(`${environment.PvpUrl}/user`,{params:searchParams});
    }

    get(id){
      return this.http.get(`${environment.PvpUrl}/user/`+id);
    }

    edit(id,formData){
      return this.http.put(`${environment.PvpUrl}/user/`+id,formData);
    }

    create(formData){
		return this.http.post(`${environment.PvpUrl}/user`,formData);
	  }

    delete(id){
      return this.http.delete(`${environment.PvpUrl}/user/`+id);
    }

    updateProfile(formData){
      return this.http.post(`${environment.PvpUrl}/user/profile`,formData);
    }

	admin(searchParams){
		return this.http.get(`${environment.PvpUrl}/user?groups[]=2&groups[]=6`,{params:searchParams});
	  }

	notAdmin(searchParams){
		return this.http.get(`${environment.PvpUrl}/user?groups[]=3&groups[]=4&groups[]=5`,{params:searchParams});
	}

	Seller(searchParams){
		return this.http.get(`${environment.PvpUrl}/user?groups[]=5`,{params:searchParams});
	}
}
