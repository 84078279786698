import { Injectable } from '@angular/core';

@Injectable()
export class QuickviewService {
  constructor() {}

  // // Get from the API
  // getNotes() {
  //   return this.http.get('assets/data/notes.json').map(res => res.json());
  // }

  // getUsers() {
  //   return this.http.get('assets/data/users.json').map(res => res.json());
  // }

  // getChatMessages() {
  //   return this.http.get('assets/data/messages.json').map(res => res.json());
  // }
}
