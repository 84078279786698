// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
	production: false,
	baseUrl: 'https://api.coredev.codeblack.mt/api/v1',//api.dev.slik.mt
	JlUrl: 'https://api.jlcleaning.codeblack.mt/api/v1',
	PvpUrl:'https://api.pacevillepass.com/api/v1',
	per_page : 25,
	mapUrl:"https://maps.google.com/maps/api/js?key=AIzaSyCDvOZTRv0cAPm3-_Jq2OA8J_0R6wEG_-s",
	ws_url: 'https://api.zenith.dev.codeblack.mt/broadcasting/auth'
  };
