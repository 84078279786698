import { Injectable } from '@angular/core';

@Injectable()
export class RoleService {
	private roles: any = {
		1: {
		  role: 'Administrator',
		  defaultRoute: '/sales_report'
		},
		2: {
		  role: 'Security',
		  defaultRoute: '/memberships'
		},
	   
	  };
	
	  constructor() { }
	
	  getDefaultRoute(role: number): string{
		// //return "/user";
		// let route = this.menuService.menuItems[0].routerLink ? this.menuService.menuItems[0].routerLink : this.menuService.menuItems[0].submenu[0].routerLink;
		// return route;
		// //return this.roles[role].defaultRoute;
		return "/session/login";
	  }
}
