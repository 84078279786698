import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { RoleService } from './role.service';
import * as $ from 'jquery';
import { NgxPermissionsService } from 'ngx-permissions';
import { ActionModel, MessageService } from '../../@pages/components/message/message.service';

@Injectable()
export class RoleGuard implements CanActivate {
  returnUrl: string;

  constructor(
    private permissionService : NgxPermissionsService,
    private router: Router,
	private roleService : RoleService,
    private messageService : MessageService,
	private authService : AuthService,
  ) { }
  
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    let _this = this;
    
    if ($('form').hasClass("ng-touched") && $('form').hasClass("blockAuto")) {
      return false;
    }

    if ($('form').hasClass("ng-touched") && !$('form').hasClass("exempt")) {
      console.log("has touched form!");
    }

    if (next.data.permission == 'view_admin') {
      if (next.url.length == 0) {
        this.router.navigate([`/dashboard`]);
      }
      return true;
    }
	
	console.log(this.permissionService.getPermissions());
    console.log("Has Permission: ",next.data.permission_name," "+this.authService.hasPermission(next.data.permission));

	
    if (!next.data.permission){
		return true;
	  }


    console.log(this.permissionService.getPermissions());
    if (!this.authService.hasPermission(next.data.permission)) {
      console.warn('Access Denied. Navigated to default route');
      let path = _this.roleService.getDefaultRoute(_this.authService.getUserRole());
      _this.router.navigate([path]);
      return false;
    }

    else if (this.permissionService.hasPermission(next.data.permission)) {
      return true;
    }
    
    else {
      console.log(`Permission/Role not recognized`);
      return false;
    }
  }
}
