import { ChangeDetectorRef, Component, EventEmitter, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../../shared/services/auth.service';
import { pagesToggleService } from '../../services/toggler.service';
import { RootLayout } from '../root/root.component';
import { NgxPermissionsService } from 'ngx-permissions';
import { LocalStoreService } from '../../../shared/services/local-store.service';
import { NotificationModel } from '../../../shared/models/notification.model';
import * as moment from 'moment';
import { NotificationService } from '../../../shared/services/notification.service';
import { WebsocketService } from '../../../shared/services/websocket.service';
import { Subject } from 'rxjs';
import { Utils } from '../../../shared/services/utils.service';

@Component({
  selector: 'simplywhite-layout',
  templateUrl: './simplywhite.component.html',
  styleUrls: ['./simplywhite.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SimplyWhiteLayout extends RootLayout implements OnInit, OnDestroy {

  forceHide:boolean = false;

  constructor(
    public toggler: pagesToggleService,
    public router: Router,
    public permissionsService: NgxPermissionsService,
    private store: LocalStoreService,
    //private notificationService: NotificationService,
    private utils : Utils,
    private cdr: ChangeDetectorRef,
    private wsService: WebsocketService,
    private authService: AuthService) {
    super(toggler, router);
  }

  ngOnInit() {
    this.filterMenu();
    //this.authService.activeCompany = JSON.parse(localStorage.getItem("activeCompany"));
    if(!this.authService.didFirstCheck){
      this.init();
      this.authService.didFirstCheck = true;
    }
    else{
      this.forceHide = true;
    }
  }

  init(){
    let _this = this;
    this.changeLayout('menu-pin');
    this.autoHideMenuPin();
    //this.loadNotifications(false);
    let timeout = setInterval(() => {
      if (_this.authService.authenticated) {
        clearTimeout(timeout);
      }
      else{
        _this.authService.checkAuth();
      }
    }, 1000);
    // setTimeout(() => {
    //   _this.authService.checkAuth();
    // }, 500);
    if (!this.authService.triggered) {
      this.authService.triggered = true;
      this.authService.initialTimeout = setTimeout(() => {
        _this.authService.initialTimeout = null;
      }, 2500);
    }
  }

  handleSocket(res) {
    console.log(res);
  }

  getHumanTime(date) {
    //console.log(asset.gps.timestamp);
    return moment.duration(moment(new Date()).diff(date)).humanize() + " ago";
  }

  filterMenu() {
	let _this = this;
    if (!this.authService.permissions) {
      if (this.store.getItem('currentUser')) {
        this.authService.permissions = this.store.getItem('currentUser')['permissions'];
      }
    }
    if (this.authService.permissions) {
      this.permissionsService.loadPermissions(this.authService.permissions);
    }
    let parentIdx = [];
    for (let parent = 0; parent < this.menuLinks.length; parent++) {
      if (!this.authService.hasPermission(this.menuLinks[parent].roles)) {
        parentIdx.push(parent);
      }
      if(this.menuLinks[parent].submenu){
        let idxList = [];
        for(let child = 0; child < this.menuLinks[parent].submenu.length;child++){
          if (this.menuLinks[parent].submenu[child]['roles'] && !this.authService.hasPermission(this.menuLinks[parent].submenu[child]['roles'])) {
            idxList.push(child);
          }
        }
        idxList.forEach(index => {
          delete _this.menuLinks[parent].submenu[index];
        });
        _this.menuLinks[parent].submenu = _this.menuLinks[parent].submenu.filter(val => val != null);
        console.log(_this.menuLinks[parent].submenu);
      }
    }
    parentIdx.forEach(index => {
      delete this.menuLinks[index];
    })
    this.menuLinks = this.menuLinks.filter(Boolean);
    
  }

  logout() {
    this.authService.signout();
  }

  isGraceMode() {
    if (!this.store.getItem('currentUser')) {
      return null;
    }
    return this.store.getItem('currentUser')['grace'];
  }

  isExpiredMode() {
    if (this.expiredModeHidden) {
      return null;
    }
    if (!this.store.getItem('currentUser')) {
      return null;
    }
    // if (this.store.getItem('currentUser')['company']['renewal']) {
    //   return this.store.getItem('currentUser')['company']['renewal']['expiry_date'];
    // }
    return null;
  }

  expiredModeHidden: boolean = false;
  hideExpiredMode() {
    this.expiredModeHidden = true;
    let updatedUser = this.store.getItem('currentUser');
    //updatedUser['company']['renewal'] = null;
    this.store.setItem('currentUser', updatedUser);
    console.log(this.store.getItem('currentUser')['company']);
  }

  getUsername() {
    if (!this.authService.user) {
      return "";
    }
    return this.authService.user.first_name + ' ' + this.authService.user.last_name;
  }

  getLogo() {
    return this.authService.getLogo();
  }

  getCompanyLogo(){
    return this.authService.getCompanyLogo();
  }

  isAuthenticated() {
    if (this.authService.initialTimeout || !this.authService.didFirstCheck) {
      return false;
    }
    return this.authService.authenticated;
  }

  profile() {
    this.router.navigate(['/profile']);
  }

  companyProfile() {
    this.router.navigate(['/company/profile']);
  }

  subscriptionModalTrigger:EventEmitter<any> = new EventEmitter<any>();
  openSubscriptionModal(){
    this.subscriptionModalTrigger.emit(true);
  }

  cardModalTrigger:EventEmitter<any> = new EventEmitter<any>();
  openCardModal(){
    this.cardModalTrigger.emit(true);
  }

}
